const purchase = 'purchase';
const deposit = 'deposit';
const transfer = 'transfer';
const depositRestricted = 'deposit_restricted';
const transferRestricted = 'transfer_restricted';
const commission = 'commission';
const withdrawal = 'withdrawal';
const adjustment = 'adjustment';

export const TRANSACTION_CATEGORIES = {
	purchase,
	deposit,
	deposit_restricted: depositRestricted,
	transfer,
	transfer_restricted: transferRestricted,
	commission,
	withdrawal,
	adjustment,
};

export default {};
