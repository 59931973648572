<template>
	<div class="animated fadeIn p-3">
		<div class="row">
			<div class="col-12">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<b-button
					v-if="this.$user.details().type === superAdmin"
					variant="primary"
					class="float-right btn mx-1"
					@click="exportTwLocalEFTWithdrawals">
					<i
						class="fas fa-file-download"
						aria-hidden="true" />
					{{ translate('export_tw_local_eft_withdrawals') }}
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div
						:class="{'col-12 col-md-6' : isAdminOrCorporate && dateRange === dateRanges.custom}"
						class="col-md">
						<div
							class="form-group">
							<label
								for="category"
								class="label">{{ translate('category') }}</label>
							<select
								id="category"
								v-model="filters.category"
								name="category"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="category in categories"
									:key="category"
									:value="category">
									{{ translate(category) }}
								</option>
							</select>
						</div>
					</div>
					<div class="col-sm">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									:value=" '' ">
									{{ translate('all') }}
								</option>
								<option
									v-for="dateRange in dateRanges"
									:key="dateRange"
									:value="dateRange">
									{{ translate(dateRange) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-sm">
						<label
							for="startDate"
							class="label">{{ translate('start_date') }}</label>
						<v-date-picker
							id="startDate"
							v-model="startDate"
							:input-props="{ class: 'form-control' }"
							:formats="calendarConfig"
							name="startDate"
							show-caps />
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-sm">
						<label
							for="endDate"
							class="label">{{ translate('end_date') }}</label>
						<v-date-picker
							id="endDate"
							v-model="endDate"
							:input-props="{ class: 'form-control' }"
							:formats="calendarConfig"
							name="endDate"
							show-caps />
					</div>
				</div>
			</template>
		</data-filter>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							v-if="isAdminOrCorporate"
							class="border-top-0 pointer"
							@click="sortByField('user_id')">
							{{ translate('user_id') }}
							<sort field="user_id" />
						</th>
						<th
							v-if="isAdminOrCorporate"
							class="border-top-0 pointer"
							@click="sortByField('username')">
							{{ translate('username') }}
							<sort field="username" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('created_at')">
							{{ translate('date') }}
							<sort field="created_at" />
						</th>
						<th class="border-top-0">
							{{ translate('description') }}
						</th>
						<th class="border-top-0">
							{{ translate('status') }}
						</th>
						<th class="border-top-0 text-right">
							{{ translate('credit') }}
						</th>
						<th class="border-top-0 text-right">
							{{ translate('debit') }}
						</th>
						<th class="border-top-0">
							{{ translate('actions') }}
						</th>
						<!-- <th
							v-if="isDistributor"
							class="border-top-0 pointer"
							@click="sortByField('associated_user_id')">
							{{ translate('user_id') }}
							<sort field="associated_user_id" />
						</th>
						<th
							v-if="isDistributor"
							class="border-top-0 pointer"
							@click="sortByField('associated.username')">
							{{ translate('username') }}
							<sort field="associated.username" />
						</th> -->
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td
							v-if="isAdminOrCorporate"
							class="align-middle">
							<span
								v-if="item.attributes.user.type === distributor"
								class="badge badge-primary pointer"
								@click="toDashboard({ distributorId: item.attributes.user.id.toString() })">
								{{ item.attributes.user.id }}
							</span>
							<span
								v-else
								class="badge badge-primary">
								{{ item.attributes.user.id }}
							</span>
						</td>
						<td
							v-if="isAdminOrCorporate"
							class="align-middle">
							{{ item.attributes.user.username }}
						</td>
						<td class="align-middle">
							{{ $moment(item.attributes.created_at.date).format(isAdminOrCorporate ? dateTimeFormat : dateFormat) }}
						</td>
						<td class="align-middle">
							{{ item.attributes.description }} {{ item.attributes.is_withdrawal ? `(${translate(item.attributes.withdrawal.payout_method)})` : '' }}
						</td>
						<td class="align-middle">
							{{ item.attributes.status ? translate(item.attributes.status) : '' }}
						</td>
						<td class="align-middle text-right">
							<span v-if="item.attributes.amount_value >= 0">
								<template v-if="item.attributes.base_amount">
									<span
										v-b-tooltip.hover.html
										:title="item.attributes.base_amount"
										class="pointer btn-link">
										{{ item.attributes.amount }}
									</span>
								</template>
								<span v-else>
									{{ item.attributes.amount }}
								</span>
							</span>
						</td>
						<td class="align-middle text-right">
							<span v-if="item.attributes.amount_value < 0">
								<template v-if="item.attributes.base_amount">
									<span
										v-b-tooltip.hover.html
										:title="item.attributes.base_amount"
										class="pointer btn-link">
										({{ item.attributes.amount }})
									</span>
								</template>
								<span v-else>
									({{ item.attributes.amount }})
								</span>
							</span>
						</td>
						<td class="align-middle">
							<b-button
								v-if="item.attributes.is_withdrawal && item.attributes.status === 'pending'"
								v-b-tooltip.hover
								:title="translate('cancel_transfer')"
								:variant="'secondary'"
								:disabled="isAdminOrCorporate"
								class="btn mx-1 bg-primary-alt"
								@click="cancelTransfer(item.id)">
								<i
									class="fa fa-ban"
									aria-hidden="true" />
							</b-button>
							<div
								v-b-tooltip.hover
								:title="item.attributes.withdrawal.crypto_address ? translate('view_details') : translate('processing')"
								class="d-inline-block">
								<b-button
									v-if="item.attributes.is_withdrawal && item.attributes.withdrawal.payout_method === 'crypto' && item.attributes.status !== 'cancelled'"
									:variant="'secondary'"
									:disabled="!item.attributes.withdrawal.crypto_address"
									class="btn mx-1 bg-primary-alt"
									target="_blank"
									:href="getBlockchainExplorerUrl(item.attributes.withdrawal)">
									<i
										class="fa fa-info-circle"
										aria-hidden="true" />
								</b-button>
							</div>
						</td>
						<!-- <td
							v-if="isDistributor"
							class="align-middle">
							{{ item.attributes.associated_user.id }}
						</td>
						<td
							v-if="isDistributor"
							class="align-middle">
							{{ item.attributes.associated_user.username }}
						</td> -->
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import DataFilter from '@/components/DataFilter';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import FiltersParams from '@/mixins/FiltersParams';
import { BLOCKCHAIN_PAGES } from '@/settings/Blockchain';
import {
	DATE_RANGES as dateRanges, V_CALENDAR_CONFIG, YMD_FORMAT, YMDHMS_FORMAT,
} from '@/settings/Dates';
import { PAGINATION } from '@/settings/RequestReply';
import { admin, distributor, superAdmin } from '@/settings/Roles';
import { TRANSACTION_CATEGORIES as categories } from '@/settings/TransactionCategories';
import { WALLET_TYPES as walletTypes } from '@/settings/Wallets';
import {
	Grids, PayoutMethods, Tooltip, Wallets as WalletMessages, WalletTransactions, WalletTypes as WalletTypesMessages,
} from '@/translations';
import Alert from '@/util/Alert';
import Wallets from '@/util/Wallets';

export default {
	name: 'WalletsTransactions',
	messages: [Grids, PayoutMethods, Tooltip, WalletMessages, WalletTransactions, WalletTypesMessages],
	components: {
		DataFilter,
	},
	mixins: [FiltersParams, DashboardRedirect],
	data() {
		return {
			admin,
			superAdmin,
			calendarConfig: V_CALENDAR_CONFIG,
			categories,
			dateFormat: YMD_FORMAT,
			dateTimeFormat: YMDHMS_FORMAT,
			dateRanges,
			distributor,
			wallets: new Wallets(),
			walletTypes,
			cancelWithdrawal: new Wallets(),
			twLocalEFTWithdrawals: new Wallets(),
			alert: new Alert(),
		};
	},
	computed: {
		cancelErrors() {
			try {
				return this.cancelWithdrawal.data.errors;
			} catch (error) {
				return [];
			}
		},
		loading() {
			return !!this.wallets.data.loading;
		},
		errors() {
			return this.wallets.data.errors;
		},
		pagination() {
			return this.wallets.data.pagination;
		},
		data() {
			try {
				const { data } = this.wallets.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			if (this.data) {
				const response = this.data.length;
				return !!response;
			}
			return false;
		},
		walletType() {
			// return this.$route.params.wallet_type;
			return walletTypes.commission;
		},
		isDistributor() {
			return this.$user.details().type === distributor;
		},
		isAdminOrCorporate() {
			return this.admin.includes(this.$user.details().type);
		},
	},
	created() {
		// this.wallets.getTransactions(this.$route.params.wallet_type);
		this.wallets.getTransactions(walletTypes.commission);
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			// this.wallets.getTransactions(this.$route.params.wallet_type, options);
			this.wallets.getTransactions(walletTypes.commission, options);
		},
		cancelTransfer(transactionId) {
			const trans = {
				title: this.translate('cancel_transfer'),
				text: this.translate('cancel_transfer_swal_text'),
			};
			const options = {
				confirmButtonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
			};
			this.alert.confirmation(trans.title, trans.text, options).then(() => {
				const payload = {
					status: 'cancelled',
				};
				this.cancelWithdrawal.cancelTransfer(transactionId, payload).then(() => {
					this.alert.toast('success', this.translate('cancel_transfer_success'));
				}).catch(() => {
					if (this.cancelErrors.status === 422 && typeof this.cancelErrors.errors.status !== 'undefined') {
						this.alert.toast('error', this.translate('cancel_transfer_error'));
					}
				}).finally(() => {
					// this.wallets.getTransactions(this.$route.params.wallet_type);
					this.wallets.getTransactions(walletTypes.commission);
					this.$parent.$parent.$parent.summary.getBalance();
				});
			}).catch(() => {});
		},
		getBlockchainExplorerUrl(withdrawal) {
			if (withdrawal.crypto_transaction_id) {
				return BLOCKCHAIN_PAGES.transaction('btc', withdrawal.crypto_transaction_id);
			}
			return BLOCKCHAIN_PAGES.address('btc', withdrawal.crypto_address);
		},
		exportTwLocalEFTWithdrawals() {
			this.twLocalEFTWithdrawals.downloadTwLocalEftWithdrawals().then(() => {
				const { response } = this.twLocalEFTWithdrawals.data;
				const url = window.URL.createObjectURL(response.data);
				const link = document.createElement('a');
				link.href = url;
				const filename = `${this.translate('tw_local_eft_system')} - ${this.$moment().format(YMDHMS_FORMAT)}.xlsx`;
				link.setAttribute('download', filename);
				this.$el.appendChild(link);
				link.click();
			});
		},
	},
};
</script>
